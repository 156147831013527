import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3100744155/src/autobackup-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "managing-api-keys"
    }}>{`Managing API keys`}</h1>
    <p>{`AutoBackup allows Users to add new API keys (which allow automation using GraphQL API) and grant permissions to them.`}</p>
    <p>{`Granting permissions is done by attaching predefined roles (READ ONLY and ADMIN) to selected API key.
`}<img alt="API keys view" src={require("../assets/permissions/api-keys/api_keys_view.png")} /></p>
    <h2 {...{
      "id": "available-roles"
    }}>{`Available roles`}</h2>
    <ul>
      <li parentName="ul">{`READ ONLY - allows to read all data for given customer,`}</li>
      <li parentName="ul">{`ADMIN - same privileges as READ ONLY and allows to create, update and delete resources (accounts, policies, setups etc.).`}</li>
    </ul>
    <h2 {...{
      "id": "adding-an-api-key"
    }}>{`Adding an API key`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Click the "Create an API key" button.
`}<img alt="Create API key button" src={require("../assets/permissions/api-keys/api_key_create.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Provide alias and click the "Create" button.
`}<img alt="Create API key form" src={require("../assets/permissions/api-keys/api_key_form.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Copy the API key.
`}<img alt="API key creation result" src={require("../assets/permissions/api-keys/api_key_creation_result.png")} /></p>
      </li>
    </ol>
    <h2 {...{
      "id": "granting-permissions"
    }}>{`Granting permissions`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Select an API key and click the "Grant customer access" button.
`}<img alt="Grant customer access" src={require("../assets/permissions/api-keys/api_key_view_no_customers.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select customer and roles from dropdowns and click "Save" button.
`}<img alt="Grant customer access form" src={require("../assets/permissions/api-keys/grant_customer_access_form.png")} /></p>
      </li>
    </ol>
    <h2 {...{
      "id": "removing-permissions"
    }}>{`Removing permissions`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Click the "Pencil" button.
`}<img alt="API key view" src={require("../assets/permissions/api-keys/api_key_view_with_customer.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click "Cross" to remove customer role from an API key.
`}<img alt="Remove access form" src={require("../assets/permissions/api-keys/remove_access_form.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click "Save" button.
`}<img alt="Remove access form - Save button" src={require("../assets/permissions/api-keys/remove_access_form_2.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Confirm action by clicking "Remove" button.
`}<img alt="Remove access confirmation" src={require("../assets/permissions/api-keys/remove_access_confirmation.png")} /></p>
      </li>
    </ol>
    <h2 {...{
      "id": "deleting-an-api-key"
    }}>{`Deleting an API key`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Choose an API key and click "Delete" button from "Three dots".
`}<img alt="Delete API key" src={require("../assets/permissions/api-keys/delete_api_key.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Confirm action by clicking "Delete" button.
`}<img alt="Delete API key confirmation" src={require("../assets/permissions/api-keys/delete_api_key_confirmation.png")} /></p>
      </li>
    </ol>
    <h2 {...{
      "id": "delay-of-the-permissions-changes"
    }}>{`Delay of the permissions changes`}</h2>
    <p>{`The important thing to remember is that the changes in the permissions made by the user don't take effect immediately.
Usually, it takes up to 5 minutes for the new permissions to apply due to the asynchronous nature of the RBAC rules evaluation.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      